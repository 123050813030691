<template>
  <div>
    <div class="row">
      <div class="col-sm-4 offset-sm-1">
      <img src="@/assets/RuthLogo.png" class="img-fluid"/>
      </div>
      <div class="col-sm-4 offset-sm-2 pt-5">
      <img src="@/assets/selfie.png" class="img-fluid"/>
      </div>
      <div class="col-sm-12">
      <h1 class="text-center my-5">Artist's Statement</h1>
      <p>
        My practice, both art and writing, examines and challenges structures of
        oppression that devalues femininity. As a feminist I believe strongly in
        the role of women as artists and makers and the social commentary about
        women's heritage of the uniquely female needlework. The decision to
        embrace textile has been an active one as I knew I was aligning myself
        with a deep history of women's labour and a craft that has been devalued
        by a male dominated institution. Ubiquitous in all cultures, the medium
        of embroidery contains its own patriarchal or submissive history, which
        I explored further in my dissertation, I now look to find how to elevate
        craft to high-art.
      </p>
      <p>
        Women's suffrage and feminist art movements have been central in
        informing my current work which explores the relationship between
        textile and protest. While I began my career as textile artist, I have
        expanded into other materials which reflect the idea of protest and
        rebellion and my surroundings in Birmingham. Most recently I have begun
        to use spray paint and incorporate a graffiti aesthetic in order to
        express my ideas of protest and activism. By exploring historically
        provocative street art my artwork aims to graduate beyond its domestic
        narratives and create a dichotomy between suburbia and rebellion.
      </p>
      <p>
        I am currently based in Coventry working in a temporary studio in my
        garden shed, this space has now become a huge part of the play with this
        dichotomy and I look to create a movement that embodies feminist
        discourse using these mechanisms of protest.
      </p>

      </div>
    </div>
    <div class="hello text-center">
    </div>
  </div>
</template>

<script>
export default {
  name: "home-page",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
