<template>
  <div class="hello text-center">
      <div class="row pt-1">
      <div class="col-sm-4">
      <img src="@/assets/ShedRiot.png" class="img-fluid"/>
      <p class="pt-3">Shed Riot</p>
      <p>Collage 180x100cm. 2021</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/ShedRiot2.png" class="img-fluid"/>
      <p class="pt-3">Shed Riot</p>
      <p>Collage 180x100cm. 2021</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/abolition.png" class="img-fluid"/>
      <p class="pt-3">Abolition</p>
      <p>Acrylic on canvas 40x62cm. 2020</p>
      </div>
   </div>
   
   
   <div class="row pt-1">
      <div class="col-sm-4">
      <img src="@/assets/Union.png" class="img-fluid"/>
      <p class="pt-3">Union</p>
      <p>Embroidery on Canvas and Cotton. 70x100cm. 2020</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/Flag.png" class="img-fluid"/>
      <p class="pt-3">Flag 1</p>
      <p>Cotton, Watercolour, Spray Paint 150x170cm. 2021</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/Flag2.png" class="img-fluid"/>
      <p class="pt-3">Flag 2</p>
      <p>Cotton, Watercolour, Spray Paint 150x170cm. 2021</p>
      </div>
   </div>
  
  
   <div class="row pt-1">
      <div class="col-sm-4">
      <img src="@/assets/LancasterCircus.png" class="img-fluid"/>
      <p class="pt-3">Lancaster Circus</p>
      <p>Spray paint on various. 2019</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/SwanIsland.png" class="img-fluid"/>
      <p class="pt-3">Swan Island</p>
      <p>Spray paint on various. 2019</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/NewCanalStreet.png" class="img-fluid"/>
      <p class="pt-3">New Canal Street</p>
      <p>Spray paint on various. 2019</p>
      </div>
   </div>

   <div class="row pt-1">
      <div class="col-sm-4">
      <img src="@/assets/StudentWithStickers1.png" class="img-fluid"/>
      <p class="pt-3">Vinyl Stickers. 2018</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/StudentWithStickers2.png" class="img-fluid"/>
      <p class="pt-3">Vinyl Stickers. 2018</p>
      </div>
      <div class="col-sm-4">
      <img src="@/assets/StudentWithStickers3.png" class="img-fluid"/>
      <p class="pt-3">Vinyl Stickers. 2018</p>
      </div>
   </div>

   <div class="row pt-1">
      <div class="col-sm-4">
      <img src="@/assets/socials.png" class="img-fluid"/>
      <p class="pt-3">2017</p>
      </div>
      <div class="col-sm-4">
      </div>
      <div class="col-sm-4">
      </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'art-view'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




</style>
