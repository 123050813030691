<template>
  <div class="hello text-center">
    <p class="lead">ruthtrusloveuk@gmail.com</p>
    <a class="lead" href="https://www.instagram.com/ruthtrusloveuk/">Instagram: @ruthtrusloveuk</a>
     <h5 class="pt-5">Exhibitions</h5>
    <p>May 2019: hyperSTEW, Centrala, Digbeth</p>
    <p>May 2018: What Matters Most, Margaret Street</p>
    <h5 class="pt-3">Education</h5>
    <p>A-Level, Art & Design: Fine Art. Bablake School, 2017</p>
    <p>AS-Level, Art & Design: Textiles. Bablake School, 2016</p>
    <p>UAL Level 3 Diploma, Art & Design (Foundational Studies). Royal Leamington Spa College, 2018</p>
    <h5 class="pt-3">Employment</h5>
    <p>Sign Painting at Vintage in the Village, Fargo Village, 2017</p>
    <p>Digital Marketing Design for The Jacobean Hotel, Coventry, 2019 till present.
Using InDesign to make menus, digital posters and instagram stories to help promote events or deals in the hotel, bar and restaurant
</p>
<p>Work Experience at The Green Dragon, Coventry, 2020. 
During the month I was working on creating their logo, I helped promote the business through social media through designing digital marketing and installed a large tree feature in the restaurant.
</p>
  </div>
</template>

<script>
export default {
  name: "contact",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
