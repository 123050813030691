<template>
  <div class="hello text-center">
    <div>
      <iframe
        class="pt-5"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/E3Zx6HKuGFk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div>
      <iframe
        class="pt-5"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/xMGwc81B0GA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <p class="pt-3">Ultimate Housewife</p>
    </div>
    <div>
      <iframe
        class="pt-5"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/52IcpNtwJ8c"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <p class="pt-3">A Message to Birmingham City University</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "video",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
